import {Pipe, PipeTransform} from '@angular/core';
import { Utils } from '../utils';

@Pipe({
  name: 'dateSince'
})
export class DateSincePipe implements PipeTransform {

  transform(milliseconds: any, args?: any): any {

      if(milliseconds <= 0 || isNaN(milliseconds)){
          return '';
      }


      const secondsAgo = Math.round(parseInt(milliseconds, 10) / 1000);
      if (isNaN(secondsAgo) || secondsAgo < 0){
          return '';
      }

      return Utils.formatDateDelta(secondsAgo);

  }

}
