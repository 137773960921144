import {Component, Input, OnInit} from '@angular/core';
import {Utils} from '@looma/shared/utils';

@Component({
    selector: 'device-status-icon',
    template: `
        <mat-icon [style.color]="statusColor" style="transform: scale(0.5,0.5);vertical-align: middle"  class="mat-icon ng-tns-c19-3 material-icons ng-star-inserted" [title]="statusText" aria-hidden="true">brightness_1</mat-icon>
    `
})
export class DeviceStatusIconComponent implements OnInit {

    @Input()
    public status: number ;

    @Input()
    public lastUpdate?: number;

    constructor() { }


    ngOnInit(): void {

    }

    get statusColor(): string{
        return Utils.getStatusColor(this.status);
    }

    get statusText(): string {
        return this.lastUpdate ? Utils.getStatusText(this.status, this.lastUpdate) : '';
    }

}
