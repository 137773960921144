import {BaseModel} from "@looma/shared/models/base_model";
import {BrandPartner} from "@looma/shared/models/brand_partner";
import {
    RetailerPromoPeriodSubmissionSchedule,
    SegmentCostEntry
} from "@looma/shared/models/retailer_promo_period_submission_schedule";
import {BrandPromoCampaign} from "@looma/shared/models/brand_promo_campaign";
import gql from "graphql-tag";

export type SubmissionType = "default" | "product_selector"

export class RetailerPromoPeriodSubmission extends BaseModel {
    id: string;
    brandPartner: BrandSubmission
    featuredProducts: BrandProductSubmission[]
    note: string
    status: string
    submittedByUser: string
    submittedByBrand: BrandPartner
    campaignCost: number
    segments: SegmentCostEntry[]
    airtime: SegmentCostEntry
    schedule: RetailerPromoPeriodSubmissionSchedule
    createdAt: Date
    brandCampaign: BrandPromoCampaign
    submissionType: SubmissionType
    changeLog: string

    assign(obj: any): this {
        super.assign(obj);
        this.assignTypedObject(obj, 'brandPartner', BrandSubmission);
        this.assignTypedObject(obj, 'submittedByBrand', BrandPartner);
        this.assignTypedArray(obj, 'featuredProducts', BrandProductSubmission);
        this.assignTypedObject(obj, 'schedule', RetailerPromoPeriodSubmissionSchedule);
        this.assignTypedObject(obj, 'brandCampaign', BrandPromoCampaign);
        return this;
    }

    getInitialProduct(): BrandProductSubmission {
        if (this.featuredProducts && this.featuredProducts.length > 0) {
            return this.featuredProducts[0]
        } else {
            return null
        }
    }

    getSupportedProducts(): BrandProductSubmission[] {
        if (this.featuredProducts && this.featuredProducts.length > 1) {
            return this.featuredProducts.slice(1)
        } else {
            return []
        }
    }

    getSubmissionThumbUrl(): string {
        if (this.featuredProducts.length > 1) {
            return this.brandPartner.logoUrl
        } else {
            return this.featuredProducts[0].thumbUrl;
        }
    }

    getSubmissionTitle() {
        if (this.featuredProducts.length > 1) {
            return this.featuredProducts.length + " Featured Products"
        } else {
            return this.featuredProducts[0].name;
        }
    }
}

export interface RetailerPromoPeriodSubmissionInput {
    id?: string
    programSubmissionScheduleId: string
    brandPartner: MixedBrandPartnerInput
    featuredProducts: MixedProductInput[]
    note?: string,
    submittedByParentBrand: string,
    segments?: SegmentCostEntry[]
    airtime: SegmentCostEntry
    submissionType: "default" | "product_selector"
    changeLog?: string
}


export interface MixedProductInput {
    uptimeBrandProduct?: UptimeBrandProductInput
    customBrandProduct?: CustomBrandProductInput
}

export interface MixedBrandPartnerInput {
    uptimeBrand?: UptimeBrandPartnerInput
    customBrand?: CustomBrandPartnerInput
}

export interface UptimeBrandProductInput {
    id: string
}

export interface CustomBrandProductInput {
    name: string
    upc?: string
    thumbUrl?: string
}

export interface UptimeBrandPartnerInput {
    id: string
}

export interface CustomBrandPartnerInput {
    name: string
    thumbUrl?: string
}

export class BrandSubmission extends BaseModel {
    id: string
    name: string
    logoUrl: string
    uptimeBrand: boolean
    products: BrandProductSubmission[] = []

    constructor(id: string, name: string, thumbUrl: string, uptimeBrand: boolean) {
        super();
        this.id = id;
        this.name = name;
        this.logoUrl = thumbUrl;
        this.uptimeBrand = uptimeBrand;
    }
}

export class BrandProductSubmission extends BaseModel {
    id: number
    name: string
    upc: string
    thumbUrl: string
    brandPartnerName: string
    uptimeProduct: boolean

    constructor(id: number, name: string, upc: string, thumbUrl: string, uptimeProduct: boolean) {
        super();
        this.id = id;
        this.name = name;
        this.upc = upc;
        this.thumbUrl = thumbUrl;
        this.uptimeProduct = uptimeProduct;
    }

    getId(): number {
        return this.id;
    }
}

export interface ProductMetrics {
    UpcCode: string
    AmtUnitsSoldLast12Weeks: string
    AvgPriceLast12Weeks: string
    PercLoopStoreSold: number
    PercStoresSold: number
    Distributor: string
    Planned: boolean
    Cost: number
    Margin: number
}

export const QUERY_SUBMISSION = gql`
query submissions($criteria: SubmissionCriteria!) {
    submissions(criteria: $criteria) {
      id
      brandPartner {
        id
        name
        logoUrl
        uptimeBrand
      }
      featuredProducts {
        id
        name
        upc
        thumbUrl
        uptimeProduct
      }
      submittedByBrand {
        id
        name
      }
      segments {
        segmentName
        segmentCost
      }
      airtime {
        segmentName
        segmentCost 
      }
      brandCampaign {
        id
        name
      }
      schedule {
        id
        dueDate
        campaignCost
        retailerPromoPeriod {
            id
            name
            startDate
            endDate
            status
        }
        preferredProduct {
        segments {
            segmentName
            products {
                id
                name
                upc_code
                thumb_url
                brand_partner {
                    id
                    looma_id
                    name
                    parentBrand {
                        id
                    }                                    
                }
            }
            }                                   
        }       
        retailerPromoProgram {
            id
            name
            coverImageDownloadUrl
            numberOfStores
            deviceSlotTypes {
                id
                deviceSlots {
                    id
                    store {
                        id
                    }
                }
            }            
        }
      }
      submittedByUser
      changeLog  
      note
      status
      createdAt      
    }
}
`
