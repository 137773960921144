import { LoomaColor } from './colors';
import { BehaviorSubject, EMPTY, merge, NEVER, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { Apollo } from 'apollo-angular';
import {
    debounce,
    distinctUntilChanged,
    flatMap,
    map,
    skip,
    startWith,
    switchMap,
    take,
    takeUntil
} from 'rxjs/operators';
import { ElementRef, isDevMode, Type } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BaseModel } from '@looma/shared/models/base_model';
import { MutationResponse } from './types/mutation_response';
import { FormControl } from '@angular/forms';
import { secrets } from '@looma/config/secrets';
import { WordFilterable } from '@looma/shared/types/word_filterable';
import { ErrorSnackbarData } from '@looma/shared/types/error-snackbar-data';
import { Identifiable, StringIdentifiable } from '@looma/shared/types/identifiable';
import { getComponentWatcher } from '@looma/shared/lifecycle_utils';
import { HttpErrorResponse } from '@angular/common/http';
import { SignInError } from '@looma/shared/auth/sign_in_error';
import { DocumentNode } from "graphql";
import { ApolloError } from "@apollo/client/core";
import { MatListOption, MatSelectionListChange } from "@angular/material/list";

const datePipe = new DatePipe('en-US');
let instanceCounter = 0;
let globalCounter = 0

export class Utils{

    static readonly BLANK_IMAGE = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=`;
    static readonly DAY_MILLIS = 24 * 60 * 60 * 1000;

    public static percToDeg(perc: number): number{
        return perc * 360;
    }

    public static percToRad(perc: number): number{
        return this.degToRad(this.percToDeg(perc));
    }

    public static degToRad(deg: number): number{
        return deg * Math.PI / 180;
    }

    public static round(no: number, decimals: number): number{
        const multiplier = Math.pow(10, decimals);
        return Math.round(no * multiplier) / multiplier;
    }

    public static itemsEqual(a: BaseModel, b: BaseModel): boolean{
        if (a && b) {
            if (a.constructor === b.constructor) {
                return a.getId() === b.getId();
            }
        }
        return a === b;
    }

    public static deepEqual(x: any, y: any): boolean{
        if (x === y) {
            return true;
        }

        const xType = typeof x, yType = typeof y;
        if (xType !== yType) {
            return false;
        }

        if (x == null) {
            return y == null;
        }

        switch (xType) {
            case 'boolean':
                break;
            case 'number':
                break;
            case 'string':
                break;
            case 'symbol':
                break;
            case 'bigint':
                break;
            case 'undefined':
                return true;
            case 'function':
                return false;
            case 'object':
                if (x.constructor !== y.constructor) {
                    return false;
                }
                if (x.toString() !== y.toString()) {
                    return false
                }

                const xKeys = Object.keys(x), yKeys = Object.keys(y);
                if (xKeys.length !== yKeys.length) {
                    return false
                }

                for (const prop of xKeys) {
                    if (!y.hasOwnProperty(prop)) {
                        return false
                    }
                    if (!Utils.deepEqual(x[prop], y[prop])) {
                        return false
                    }
                }
                return true;
            default:
                return false
        }


    }

    static makeUtcDateString(d: Date){
        return Utils.makeUtcDate(d).toISOString()
    }

    static makeUtcDate(d: Date){
        return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes()))
    }

    public static formatDate(date: Date, pattern: string): string{
        return datePipe.transform(date, pattern)
    }

    public static formatShortDate(date: Date): string{
        return Utils.formatDate(date, 'yyyy-MM-dd');
    }

    public static getStatusColor(status: number): string{
        if (status <= 0) {
            return '#999999';
        }
        switch (status) {
            case 1:
                return LoomaColor.Green;
            case 2:
                return LoomaColor.Yellow;
            case 3:
                return LoomaColor.Orange;
            default:
                return LoomaColor.Red;

        }
    }

    public static formatDateDelta(secondsAgo: number, casing?: 'capitalize' | 'uppercase'): string {

        const casingFunction = (s: string) => {
            switch(casing) {
                case 'capitalize':
                    return s.charAt(0).toUpperCase() + s.slice(1)
                case 'uppercase':
                    return s.toUpperCase()
                default:
                    return s
            }
        }
        
        const pluralize = (unitLabel: string, unitAmount: number) => `${unitAmount} ${casingFunction(unitLabel)}${unitAmount > 1 ? 's' : ''}`

        if (secondsAgo < 0){
            return '';
        }
  
        if (secondsAgo < 30){
            return 'just now';
        }
  
        if (secondsAgo < 60){
            return pluralize('second', secondsAgo);
        }
  
        const minutesAgo = Math.round(secondsAgo / 60);
  
        if (minutesAgo < 60){
            return pluralize('minute', minutesAgo);
        }
  
        const hoursAgo = Math.round(minutesAgo / 60);
  
        if (hoursAgo < 24){
            return pluralize('hour', hoursAgo);
        }
  
        const daysAgo = Math.round(hoursAgo / 24);
  
        return pluralize('day', daysAgo);
    }

    public static getStatusText(status: number, lastUpdate: number): string{
        if (status <= 0) {
            return 'N/A';
        }
        switch (status) {
            case 1:
                return 'Online';
            default:
                return `Last Heard ${Utils.formatDateDelta(lastUpdate / 1000, 'capitalize')} Ago`;

        }
    }

    public static getApiEndpoint(...segments: string[]): string{
        segments.unshift(secrets.apiEndpoint);
        const pathDelimiter = '/';
        return segments.map(segment => {
            if (segment.endsWith(pathDelimiter)) {
                segment = segment.substring(0, segment.lastIndexOf(pathDelimiter))
            }
            return segment
        }).join(pathDelimiter);
    }

    public static getMainEndpoint(): string{
        const parts = secrets.apiEndpoint.split("api");
        if (parts.length > 1) {
            parts[parts.length - 2] += parts.pop();
        }
        return parts.join("api");
    }


    public static cloneObject<T>(t: T): T{
        return JSON.parse(JSON.stringify(t)) as T;
    }

    public static isUnsubscribed(...subs: Subscription[]): boolean{
        for (const s of subs) {
            if (s?.closed === false) {
                return false
            }
        }
        return true
    }

    public static unsubscribe(s: Subscription | Subscription[] | null | undefined): void{
        let subs = s;
        if (!Array.isArray(subs)) {
            subs = [ s ] as Subscription[]
        }
        if (Array.isArray(subs)) {
            for (const item of subs) {
                if (!(item instanceof Subscription)) {
                    continue
                }
                if (!item.closed) {
                    item.unsubscribe()
                }
            }
        }
    }

    public static groupBy<T, V>(list: T[], keyGetter: (T) => V): Map<V, T[]>{
        if (!list) {
            return undefined
        }
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [ item ]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    public static clearApolloCache(apollo: Apollo): Observable<boolean>{
        if (apollo) {
            const client = apollo.getClient();
            if (client) {
                return of(client.clearStore()).pipe(
                    map(_ => true)
                )
            } else {
                return of(true)
            }
        }
    }

    public static onResize(el: ElementRef): Observable<BoundingRect>{

        if (!el) {
            return EMPTY;
        }

        const nativeEl = el.nativeElement;


        const source: Observable<ElementRef> = new Observable(subscriber => {
            const observer = new ResizeObserver(_ => {
                subscriber.next(el)
            });

            observer.observe(nativeEl);

            return () => {
                observer.unobserve(nativeEl);
                observer.disconnect();
            }
        });


        return source.pipe(
            startWith(el),
            debounce(() => timer(100)),
            map(value => {
                return Utils.getBox(el.nativeElement as HTMLElement)

            })
        )

    }

    public static getBox(el: HTMLElement): BoundingRect{
        return {
            width: el.clientWidth,
            height: el.clientHeight,
            top: el.clientTop,
            left: el.clientLeft,
        }
    }

    public static getMimeTypes(fileTypes: string[]): string{
        const mimes = [];
        for (const fType of fileTypes) {
            switch (fType) {
                case 'image':
                    mimes.push('image/png,image/jpeg,image/jpg');
                    break;
                case 'video':
                    mimes.push('video/mp4');
                    break;
                case 'csv':
                    mimes.push('text/csv');
                    break;
            }
        }
        return mimes.join(',')
    }

    public static getMimeTypeExtensions(fileTypes: string[]): string{
        const mimes = [];
        for (const fType of fileTypes) {
            switch (fType) {
                case 'image':
                    mimes.push('.png,.jpeg,.jpg');
                    break;
                case 'video':
                    mimes.push('.mp4');
                    break;
                case 'csv':
                    mimes.push('.csv');
                    break;
                case 'html':
                    mimes.push('.html, .htm');
                    break;
                case 'pdf':
                    mimes.push('.pdf');
                    break;
            }
        }
        return mimes.join(',')
    }

    public static formatBytes(bytes, decimals = 2): string{
        if (bytes == 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = [ 'Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' ];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    public static makePercent(current: number, total: number): number{
        return Math.min(100, Math.round(Math.max(current / total, 0) * 100));
    }

    public static extractErrorMessage(error: any, defaultMessage: string): string{

        if (typeof error === 'object' && error.hasOwnProperty('message')) {
            return error.message + ''
        } else if (error instanceof Error) {
            return error.message
        } else if (Utils.isString(error)) {
            return error + ''
        }
        return defaultMessage;
    }

    public static isString(v: any): boolean{
        return typeof v === 'string' || (v instanceof String)
    }

    public static isFunction(v: any): boolean{
        return typeof v === 'function';
    }

    public static isDate(v: any): boolean{
        return v instanceof Date;
    }

    public static isNumber(v: any): boolean{
        return typeof v === 'number' || (v instanceof Number)
    }

    public static isBoolean(v: any): boolean{
        return typeof v === 'boolean' || (v instanceof Boolean)
    }

    public static isObject(v: any): boolean{
        return (v === Object(v) && !Array.isArray(v));
    }

    public static isNullOrUndefined(v: any): boolean{
        if (v == null) {
            return true
        }
        if (typeof v == 'undefined') {
            return true
        }
        return false
    }

    public static filterByWords<T extends WordFilterable>(dataSource: Observable<T[]>, filterSource: Observable<any>): Observable<T[]>{
        const wordsCache = new Map<any, string[]>();

        const getCachedWords = (obj: T): string[] => {
            let words = wordsCache.get(obj.getId());
            if (!words) {
                words = Utils.extractWords(...obj.getPhrases());
                wordsCache.set(obj.getId(), words);
            }
            return words;
        };


        const invalidateFilterSubject = new Subject();


        return dataSource.pipe(
            flatMap(filterableItems => {
                invalidateFilterSubject.next(null);
                return filterSource.pipe(
                    switchMap(value => {
                        let strValue: string = null;
                        if (Utils.isNumber(value)) {
                            strValue = value + '';
                        } else if (Utils.isString(value)) {
                            strValue = value.trim();
                        } else if (!value) {
                            strValue = ''
                        } else {
                            return EMPTY
                        }
                        return of(strValue);
                    }),
                    distinctUntilChanged(),
                    takeUntil(invalidateFilterSubject.pipe(skip(1))), // new data available
                    map((filterValue: string) => {

                        if (filterValue == '') {
                            return [].concat(filterableItems);
                        }

                        const filterWords = Utils.extractWords(filterValue);
                        if (!filterWords.length) {
                            return filterableItems;
                        }
                        const weightedRes: { obj: T, weight: number }[] = [];
                        for (const item of filterableItems) {
                            const itemWords = getCachedWords(item);
                            let matchWeight = 0;
                            for (const filterWord of filterWords) {
                                for (const bpWord of itemWords) {
                                    if (bpWord.indexOf(filterWord) >= 0) {
                                        matchWeight += filterWord.length;
                                    }

                                }
                            }
                            if (matchWeight > 0) {
                                weightedRes.push({ obj: item, weight: matchWeight })
                            }

                        }
                        return weightedRes.sort((a, b) => b.weight - a.weight).map(value => value.obj)
                    })
                )
            })
        );

    }

    public static extractWords(...phrases: string[]): string[]{
        const words: string[] = [];
        for (const phrase of phrases) {
            if (!Utils.isString(phrase)) {
                continue
            }
            for (const word of phrase.toLowerCase().trim().split(' ')) {
                if (word !== '') {
                    words.push(word);
                }
            }

        }

        return words;
    }

    public static itemsTheSame(a: Identifiable, b: Identifiable): boolean{
        if (a === b) {
            return true
        }
        if (!a) {
            return !b
        } else if (!b) {
            return !a
        }
        return a.constructor === b.constructor && a.getId() === b.getId();
    }

    public static removeItem<T extends Identifiable>(collection: T[], item: T): boolean{
        if (!item) {
            return
        }
        let idx = collection.indexOf(item);
        if (idx < 0) {
            const other = collection.find(value => value && (value.getId() === item.getId()));
            if (other) {
                idx = collection.indexOf(other)
            }
        }
        if (idx >= 0) {
            collection.splice(idx, 1);
            return true
        }

        return false;

    }

    public static extractGraphqlErrorMessage(e: Error): string{
        const msgField = 'graphQLErrors';
        if (e.hasOwnProperty(msgField) && Array.isArray(e[msgField])) {
            return ((e[msgField]) as { message: string }[]).map(value => value.message).join('; ')
        }
        return e.message
    }

    public static appendCssRulesUntil(obs: Observable<any>, rootEl: ElementRef | HTMLElement, ...styles: string[]): HTMLStyleElement{
        const s = Utils.appendCssRules(rootEl, ...styles);
        obs.pipe(
            take(1)
        ).subscribe(value => {
            if (s.parentNode) {
                const styleSheet = s.sheet as CSSStyleSheet;
                styleSheet.disabled = true;
                s.parentNode.removeChild(s)
            }
        });

        return s;
    }

    public static rotateImage(imgSrc: string, clockwise: boolean): Observable<Blob>{

        const loadImage = new Observable<HTMLImageElement>(subscriber => {
            const img = new Image()

            img.onload = _ => {
                subscriber.next(img)
                subscriber.complete()
            }

            img.setAttribute('crossorigin', 'anonymous');
            img.src = imgSrc
        });


        return loadImage.pipe(
            flatMap(img => {
                return new Observable<Blob>(subscriber => {
                    const canvas = document.createElement('canvas') as HTMLCanvasElement;

                    const width = img.naturalWidth;
                    const height = img.naturalHeight;

                    canvas.width = height
                    canvas.height = width

                    const degrees = clockwise ? 90 : -90;

                    const ctx = canvas.getContext('2d');

                    // ctx.clearRect(0,0,canvas.width,canvas.height);
                    ctx.save();
                    if (clockwise) {
                        ctx.translate(height, 0);
                    } else {
                        ctx.translate(0, width);
                    }
                    ctx.rotate(degrees * Math.PI / 180);
                    ctx.drawImage(img, 0, 0);
                    ctx.restore();

                    canvas.toBlob(blob => {
                        subscriber.next(blob)
                        subscriber.complete()
                    }, 'image/png')
                })
            })
        )
    }

    public static appendCssRules(rootEl: ElementRef | HTMLElement, ...styles: string[]): HTMLStyleElement{
        const doc = window.document;

        const elRef = rootEl as ElementRef
        let nativeEl = rootEl as HTMLElement
        if (elRef?.nativeElement) {
            nativeEl = elRef.nativeElement
        }
        if (!nativeEl) {
            return
        }

        const nodeIdAttr = 'data-node-id';
        if (!nativeEl.hasAttribute(nodeIdAttr)) {
            const instanceValue = (instanceCounter++) + '';
            nativeEl.setAttribute(nodeIdAttr, instanceValue);
            nativeEl.setAttribute(`${nodeIdAttr}-${instanceValue}`, instanceValue)
        }

        const hostStyleSelector = `[${nodeIdAttr}-${nativeEl.getAttribute(nodeIdAttr)}]`;

        const styleEl = doc.createElement('style');
        document.body.appendChild(styleEl);

        const styleSheet = styleEl.sheet as CSSStyleSheet;

        for (const ruleText of styles) {
            styleSheet.insertRule(`${hostStyleSelector} ${ruleText}`, styleSheet.cssRules.length);
        }
        return styleEl
    }

    static getNestedObject(src: any, ...props: string[]): any{
        for (let prop = props.shift(); prop && src; prop = props.shift()) {
            if (src.hasOwnProperty(prop)) {
                src = src[prop]
            } else {
                return null
            }
        }
        return src
    }

    static getNestedTypedObject<T extends BaseModel>(src: any, typeOfT: Type<T>, ...props: string[]): T{
        src = Utils.getNestedObject(src, ...props)
        if (src) {
            return new typeOfT().assign(src)
        }
        return null
    }

    static getNestedTypedArray<T extends BaseModel>(src: any, typeOfT: Type<T>, ...props: string[]): T[]{
        src = Utils.getNestedObject(src, ...props);
        if (Array.isArray(src)) {
            return (src as any[]).map(value => {
                return new typeOfT().assign(value)
            })
        }
        return null
    }

    static assignStringPair(dest: any, src: any, srcPropertyName: string, thisPropertyName?: string){
        const res: { [key: string]: string } = {};

        if (src && src.hasOwnProperty(srcPropertyName) && Array.isArray(src[srcPropertyName])) {
            for (const item of (src[srcPropertyName]) as any[]) {
                if (item.hasOwnProperty('key') && item.hasOwnProperty('value')) {
                    res[item.key] = item.value
                }
            }
        }

        dest[thisPropertyName || srcPropertyName] = res;
    }

    static onDestroy(target: any): Observable<any>{
        const c = getComponentWatcher(target);
        if (!c) {
            if (isDevMode()) {
                throw new Error('not a component or component missing @LifecycleHooks annotation')
            } else {
                return of(true)
            }
        }

        return c.destroySubject;
    }

    static getId(item: Identifiable | StringIdentifiable | string | number){
        const strIdentifiable = item as StringIdentifiable
        if (Utils.isFunction(strIdentifiable.getStringId)) {
            item = strIdentifiable.getStringId() || item
        }
        const identifiable = item as Identifiable;
        if (Utils.isFunction(identifiable.getId)) {
            item = identifiable.getId();
        }
        if (!item) {
            return
        }
        return item.toString();
    }

    static mouseEventWithin(ev: MouseEvent, elRef: ElementRef<any>): boolean{
        const rect = elRef.nativeElement.getBoundingClientRect();
        if (rect.x <= ev.clientX && rect.x + rect.width >= ev.x) {
            if (rect.y <= ev.clientY && rect.y + rect.height >= ev.y) {
                return true
            }
        }
        return false
    }

    static getDayDurationString(fromDate: Date, toDate: Date, inclusive: boolean = false): string{
        if ((fromDate instanceof Date) && (toDate instanceof Date)) {

            const days = Math.floor((toDate.getTime() - fromDate.getTime()) / (24 * 60 * 60 * 1000)) + (inclusive ? 1 : 0);
            const suffix = days == 0 || Math.abs(days) > 1 ? 'days' : 'day';
            return `${days} ${suffix}`
        }
        return ''

    }

    static extractMutationErrors(resp: MutationResponse<any>): ErrorSnackbarData{
        if (!resp || resp.success) {
            return null
        }
        const data: ErrorSnackbarData = {
            messages: [],
            title: resp.message
        }

        if (resp.validationErrors && resp.validationErrors.length) {
            const msgs = new Set<string>();
            for (const err of resp.validationErrors) {
                for (const msg of err.errors) {
                    msgs.add(msg)
                }
            }
            data.messages = Array.from(msgs)
        }
        return data
    }

    static flatten<T>(arr: any): T[]{
        if (Array.isArray(arr)) {
            if (Utils.isFunction(arr['flat'])) {
                return arr['flat']() as T[]
            }
        }
        throw new Error('Array.prototype.flat not implemented')
    }

    static extractGqlFragmentName(doc: DocumentNode, fragmentName?: string): string{
        let gqlFragmentName: string;
        for (const def of doc.definitions) {
            if (def.kind == 'FragmentDefinition') {
                if (Utils.isString(fragmentName)) {
                    if (def.name.value == fragmentName) {
                        gqlFragmentName = def.name.value
                        break
                    }
                } else {
                    if (gqlFragmentName) {
                        throw new Error(`multiple fragment definitions`)
                    }
                    gqlFragmentName = def.name.value
                }


            }
        }

        return gqlFragmentName
    }

    static generateUniqueId(): string{
        // Math.random should be unique because of its seeding algorithm
        return Math.random().toString(36).substr(2, 9);
    }

    static nextNumericId(): number{
        return ++globalCounter
    }

    static onFormControlsValueChanges(controls: FormControl[]): Observable<{ control: FormControl, value: any }>{
        const sources = controls.reduce((accum: Observable<{
            control: FormControl,
            value: any
        }>[], control: FormControl) => {
            if (control instanceof FormControl) {
                const source = control.valueChanges.pipe(
                    map(controlValue => {
                        return { control: control, value: controlValue }
                    })
                )
                accum.push(source);
            }
            return accum
        }, [])

        if (sources.length == 0) {
            return NEVER
        }

        return merge(...sources)

    }

}


export interface FileProgressData{
    blob?: Blob
    total: number;
    loaded: number;
}


export interface BoundingRect{
    width: number,
    height: number,
    left: number,
    top: number
}


export enum YesNoAny{
    Yes = 'Yes',
    No = 'No',
    Any = 'Any',
}

export function getYesNoAnyBooleanValue(opt: YesNoAny): boolean | null{
    switch (opt) {
        case YesNoAny.Yes:
            return true;
        case YesNoAny.No:
            return false;
        default:
            return null;
    }
}


export const YesNoAnyValues: YesNoAny[] = [ YesNoAny.Any, YesNoAny.Yes, YesNoAny.No ];


export class SearchFormControl<T extends BaseModel> extends FormControl{
    stream: Observable<T[]>;
    private dataSubject = new BehaviorSubject<T[]>([]);
    private subscriptions: Subscription[] = [];

    selection: T;

    constructor(){
        super();
        const source = this.dataSubject.asObservable() as Observable<WordFilterable[]>;
        this.stream = Utils.filterByWords(source, this.valueChanges.pipe(startWith(''))) as Observable<T[]>;

        const sub = this.onSelectionChanged().subscribe(value => {
            this.selection = value;
        });
        this.subscriptions.push(sub);

    }

    setData(data: T[]){
        this.dataSubject.next(data);
    }

    onSelectionChanged(): Observable<T>{
        return this.valueChanges.pipe(
            map(value => {
                if (value instanceof BaseModel) {
                    return value
                }
                return null
            }),
            distinctUntilChanged((x: T, y: T) => {
                if (x && y) {
                    return x.getId() == y.getId()
                }
                if (!x && !y) {
                    return true
                }
                return x == y;
            })
        )
    }

    getDisplayText(entry: T): string{
        if (entry) {
            return entry.getDisplayName()
        }
        return null;
    }


    destroy(){
        for (const sub of this.subscriptions) {
            sub.unsubscribe()
        }
        this.dataSubject.complete();
    }


}

export class YesNoAnyFormControl extends FormControl{
    private _value = YesNoAny.Any;
    private _defaultValue = YesNoAny.Any;

    constructor(defaultValue?: YesNoAny){
        super();
        this.setValue(defaultValue || YesNoAny.Any);
        this._defaultValue = this._value;
    }

    get options(){
        return YesNoAnyValues
    }

    // @ts-ignore
    get value(): YesNoAny{
        return this._value
    }

    set value(v: YesNoAny){
        this.applyValue(v);
    }

    setValue(v: YesNoAny){
        if (this.applyValue(v)) {
            super.setValue(v);
        }
    }

    private applyValue(v: YesNoAny): boolean{
        switch (v) {
            case YesNoAny.Any:
            case YesNoAny.No:
            case YesNoAny.Yes:
                if (this._value != v) {
                    this._value = v;
                    return true
                }
                return false;
            default:
                return false;
        }
    }

    reset(formState?: any, options?: { onlySelf?: boolean; emitEvent?: boolean }): void{
        this.setValue(this._defaultValue);
    }
}


export class BundleSubscription{
    private subs: Subscription[] = [];

    add(s: Subscription){
        this.subs.push(s)
    }

    unsubscribe(){
        for (const s of this.subs) {
            s.unsubscribe()
        }
        this.subs.length = 0;
    }
}

export class ColorSpace{

    constructor(private alpha = 1){

    }

    static readonly colors = [
        '#FF5722',
        '#673AB7',
        '#2196F3',
        '#4CAF50',
        '#E91E63',
        '#795548',

        '#9C27B0',
        '#FF9800',
        '#3F51B5',
        '#607D8B',
        '#8BC34A',
        '#9E9E9E',

        '#EA9560',

        '#00BCD4',

        '#03A9F4',
    ];

    private assignedColors = new Map<string, string>();

    static hexToRGBA(hex, alpha){
        if (alpha < 0 || alpha > 1) {
            return hex;
        }
        const r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        if (alpha) {
            return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
        } else {
            return 'rgb(' + r + ', ' + g + ', ' + b + ')';
        }
    }

    getColor(key: string){
        if (!this.assignedColors.has(key)) {
            let color = ColorSpace.colors[this.assignedColors.size % (ColorSpace.colors.length - 1)];
            if (this.alpha > 0 && this.alpha < 1) {
                color = ColorSpace.hexToRGBA(color, this.alpha)
            }
            this.assignedColors.set(key, color)
        }
        return this.assignedColors.get(key);
    }

}

export class ColorSequence{
    private _available: string[] = []

    constructor(){
        this.reset()
    }

    nextColor(){
        if (this._available.length) {
            return this._available.shift()
        }
        return this.randomColor()
    }

    private randomColor(){
        return generateRandomColor(128, 1)
    }

    freeColor(color: string){
        if (ColorSpace.colors.indexOf(color) >= 0) {
            this._available.push(color)
        }
    }

    reset(){
        this._available = [ ...ColorSpace.colors ]
    }
}

export function generateRandomColor(maxChannel = 256, alpha: number | string = Math.random().toFixed(2)){

    maxChannel = Math.min(256, Math.max(0, maxChannel))

    // 128 because we want dark colors
    const r = Math.floor(Math.random() * maxChannel);
    const g = Math.floor(Math.random() * maxChannel);
    const b = Math.floor(Math.random() * maxChannel);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}


export function getNetworkError(err: Error): HttpErrorResponse{
    if (err instanceof ApolloError) {
        err = (err as ApolloError).networkError
    }
    if (err instanceof HttpErrorResponse) {
        return err as HttpErrorResponse
    }
    return null;
}

export function isUnauthorizedRequestError(err: Error): boolean{
    const netErr = getNetworkError(err);
    if (netErr) {
        return netErr.status === 401;
    } else if (err instanceof ApolloError) {
        if (Array.isArray(err.graphQLErrors) && err.graphQLErrors.length) {
            return err.graphQLErrors[0]['message'] === 'permission denied'
        }
    } else if (err === SignInError.UnauthorizedRequestError) {
        return true
    }
    return false;
}

interface RotatedImageInfo{
    angle: number;
    url: string;
}

export class ImageSource{
    private imageSubject = new BehaviorSubject<RotatedImageInfo>({ url: this.originalUrl, angle: 0 })
    private rotateImageSub: Subscription;
    private imageAngles = new Map<number, RotatedImageInfo>();

    constructor(private originalUrl){
        this.setRotatedImageInfo(this.imageSubject.value)
    }

    rotate(clockwise: boolean){
        if (Utils.isUnsubscribed(this.rotateImageSub)) {
            const original = this.imageSubject.value

            let nextAngle = (this.imageSubject.value.angle + (clockwise ? 90 : -90)) % 360;
            if (nextAngle < 0) {
                nextAngle = 360 + nextAngle;
            }

            if (this.imageAngles.has(nextAngle)) {
                this.setRotatedImageInfo(this.imageAngles.get(nextAngle), original)
                return
            }

            this.rotateImageSub = Utils.rotateImage(this.imageSubject.value.url, clockwise).subscribe(value => {
                this.setRotatedImageInfo({
                    url: URL.createObjectURL(value),
                    angle: nextAngle
                }, original)
            })
        }

    }

    resetRotation(){
        if (this.imageSubject.value.angle != 0) {
            this.setRotatedImageInfo(this.imageAngles.get(0))
        }
    }

    onUrlChanged(): Observable<string>{
        return this.imageSubject.pipe(
            map(value => value.url)
        )
    }

    onRotationChanged(): Observable<number>{
        return this.imageSubject.pipe(
            map(value => value.angle),
            distinctUntilChanged()
        )
    }


    get isBusy(): boolean{
        return !Utils.isUnsubscribed(this.rotateImageSub)
    }

    private setRotatedImageInfo(x: RotatedImageInfo, expectCurrent = this.imageSubject.value){
        this.imageAngles.set(x.angle, x)
        if (this.imageSubject.value != x) {
            if (this.imageSubject.value == expectCurrent) {
                this.imageSubject.next(x)
            }
        }
    }
}

export const foldLeft = <A, B>(xs: Array<A>, zero: B) => (f: (b: B, a: A) => B): B => {
    const len = xs.length;
    if (len === 0) {
        return zero;
    } else {
        const head = xs[0];
        const tails = xs.slice(1);
        return foldLeft(tails, f(zero, head))(f);
    }
}


export type Constructor<T = {}> = new (...args: any[]) => T;
const EMPTY_DESTROY_FN = () => {
}


export function isMobileBrowser(){
    return (window?.innerWidth || 1000) < 800
}

export function generatePreviewThumbnail(file: File | Blob | string, maxWidth: number = 200, maxHeight: number = 200): Observable<string>{

    return loadImage(file).pipe(
        map(img => {
            let { width, height } = img

            const ratio = Math.min(maxWidth / width, maxHeight / height)
            if (ratio < 1) {
                width *= ratio
                height *= ratio
            }

            const canvas = document.createElement('canvas')
            canvas.width = width
            canvas.height = height
            canvas.style.cssText = `position: fixed; left: 0; top: 0; width: ${width}px; height: ${height}px; z-index: 9999; display: none;`
            document.body.appendChild(canvas)

            const ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0, width, height)
            const dataURL = canvas.toDataURL()
            document.body.removeChild(canvas)

            return dataURL
        })
    )
}

export type ImageSourceType = File | Blob | string | HTMLImageElement

export function loadImage(file: File | Blob | string | HTMLImageElement): Observable<HTMLImageElement>{

    if (file instanceof Image) {
        return of(file)
    }

    return new Observable<HTMLImageElement>(subscriber => {

        let isCanceled = false

        let isValid = true
        if (!file) {
            isValid = false
        } else if ((file instanceof File) || (file instanceof Blob)) {
            if (!file.type || !file.type.startsWith('image/')) {
                isValid = false
            }
        }

        if (!isValid) {
            subscriber.error('invalid image')
            return
        }

        const img = new Image()

        img.onload = () => {
            if (!isCanceled) {
                subscriber.next(img)
                subscriber.complete()
            }
        }
        img.crossOrigin = 'anonymous'
        if (typeof file == 'string') {
            img.src = file
        } else {
            img.src = window.URL.createObjectURL(file)
        }

        return () => {
            isCanceled = true
        }
    })
}

interface IObject{
    [key: string]: any;
}

type IDeepMerge = (target: IObject, ...sources: Array<IObject>) => IObject;

export const deepMerge: IDeepMerge = (target: IObject, ...sources: Array<IObject>): IObject => {
    // return the target if no sources passed
    if (!sources.length) {
        return target;
    }

    const result: IObject = target;
    const isObject = Utils.isObject

    if (isObject(result)) {
        const len: number = sources.length;

        for (let i = 0; i < len; i += 1) {
            const elm: any = sources[i];

            if (isObject(elm)) {
                for (const key in elm) {
                    if (elm.hasOwnProperty(key)) {
                        if (isObject(elm[key])) {
                            if (!result[key] || !isObject(result[key])) {
                                result[key] = {};
                            }
                            deepMerge(result[key], elm[key]);
                        } else {
                            if (Array.isArray(result[key]) && Array.isArray(elm[key])) {
                                // concatenate the two arrays and remove any duplicate primitive values
                                result[key] = Array.from(new Set(result[key].concat(elm[key])));
                            } else {
                                result[key] = elm[key];
                            }
                        }
                    }
                }
            }
        }
    }

    return result;
};

export interface Rect{
    left: number,
    top: number,
    width: number,
    height: number
}


export function insetRect(rect: Rect, paddingX = 0, paddingY = paddingX): Rect{
    return {
        left: rect.left + paddingX,
        top: rect.top + paddingY,
        width: rect.width - 2 * paddingX,
        height: rect.height - 2 * paddingY,
    }
}

export function scaleRect(rect: Rect, scale): Rect{
    return insetRect(rect, rect.width * (1 - scale) / 2, rect.height * (1 - scale) / 2)
}

export function onWindowResize(emitNow = true): Observable<boolean>{
    return new Observable(subscriber => {
        const resizeHandler = () => {
            subscriber.next(true)
        }
        window.addEventListener('resize', resizeHandler)

        if (emitNow) {
            subscriber.next(true)
        }

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    })
}


export function getSelectionOption(ev: MatSelectionListChange): MatListOption{
    for (const opt of ev.options) {
        if (opt.selected) {
            return opt
        }
    }
}

export interface StoreMarkerProps{
    text: string
    fillColor: string
    strokeColor?: string
    textColor?: string

}

export function createStoreMarker(props: StoreMarkerProps){
    // Create a canvas element
    const size = 30, halfSize = size / 2, fontSize = size * 0.4
    const strokeWidth = 1
    const canvas = document.createElement('canvas');
    canvas.width = canvas.height = size;

    const strokeColor = props.strokeColor || darkenColor(props.fillColor, 20)

    const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}">
          <circle cx="50%" cy="${halfSize}" r="${halfSize - strokeWidth}" fill="${props.fillColor}" stroke="${strokeColor}" stroke-width="${strokeWidth}"/>
          <text  x="50%" y="50%"  text-anchor="middle" dy=".3em" font-size="${fontSize}" font-weight="bold" fill="#fff">${props.text}</text>
        </svg>
    `
    return `data:image/svg+xml;charset=UTF-8;base64,${btoa(svg)}`

    // Get the canvas context and draw the circle
    const context = canvas.getContext('2d');
    context.beginPath();
    context.arc(halfSize, halfSize, halfSize - strokeWidth, 0, 2 * Math.PI);
    context.fillStyle = props.fillColor;
    context.fill();
    context.strokeStyle = strokeColor;
    context.lineWidth = strokeWidth;
    context.stroke();

    // Draw the number in the center of the circle
    context.font = `bold ${fontSize}px sans-serif`;
    context.fillStyle = props.textColor || '#fff';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(props.text, halfSize, halfSize);

    return canvas.toDataURL()
}

function darkenColor(color: string, percent: number): string{
    // Check if the color is in RGB or RGBA format
    const isRgb = color.startsWith('rgb(');
    const isRgba = color.startsWith('rgba(');

    if (isRgb || isRgba) {
        // Parse the RGB or RGBA components
        const match = color.match(/(\d+),\s*(\d+),\s*(\d+)(,\s*(\d+(\.\d+)?))?/);
        const red = parseInt(match[1]);
        const green = parseInt(match[2]);
        const blue = parseInt(match[3]);
        const alpha = match[5] ? parseFloat(match[5]) : 1;

        // Convert the RGB or RGBA components to hex format
        const hexColor = `#${red.toString(16)}${green.toString(16)}${blue.toString(16)}`;

        // Darken the hex color
        const newHexColor = darkenHexColor(hexColor, percent);

        // Convert the new hex color to RGBA format, if necessary
        if (isRgba) {
            const newAlpha = alpha.toString();
            return `rgba(${parseInt(newHexColor.substr(1, 2), 16)}, ${parseInt(newHexColor.substr(3, 2), 16)}, ${parseInt(newHexColor.substr(5, 2), 16)}, ${newAlpha})`;
        } else {
            return newHexColor;
        }
    } else {
        // Darken the hex color directly
        return darkenHexColor(color, percent);
    }
}


export function darkenHexColor(hexColor: string, percent: number): string{
    const red = parseInt(hexColor.substr(1, 2), 16);
    const green = parseInt(hexColor.substr(3, 2), 16);
    const blue = parseInt(hexColor.substr(5, 2), 16);

    // Calculate the new red, green, and blue values based on the percentage
    const newRed = Math.round(red * (1 - percent / 100));
    const newGreen = Math.round(green * (1 - percent / 100));
    const newBlue = Math.round(blue * (1 - percent / 100));

    // Convert the new red, green, and blue values back to a hex string
    const newColor = `#${newRed.toString(16)}${newGreen.toString(16)}${newBlue.toString(16)}`;

    return newColor;
}

function rowToCsvString(row: any[]){
    let finalVal = '';
    for (let j = 0; j < row.length; j++) {
        let innerValue = row[j] === null ? '' : row[j].toString();
        if (row[j] instanceof Date) {
            innerValue = row[j].toLocaleString()
        }
        let result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) {
            result = '"' + result + '"';
        }
        if (j > 0) {
            finalVal += ',';
        }
        finalVal += result;
    }
    return finalVal + '\n';
}

export function exportToCsv(filename: string, rows: any[]){
    let csvContents = '';

    for (const row of rows) {
        csvContents += rowToCsvString(row);
    }

    const blob = new Blob([ csvContents ], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export class GenericError extends Error{
    constructor(msg){
        super(msg)
        this.name = "GenericError"
    }
}

export async function gzipJson(data: any){
    const stream = new Blob([ JSON.stringify(data) ], {
        type: 'application/json',
    }).stream();

    const compressedReadableStream = stream.pipeThrough(
        new window['CompressionStream']("gzip")
    );

    const compressedResponse = new Response(compressedReadableStream);

    const blob = await compressedResponse.blob();
    const buffer = await blob.arrayBuffer();

    return btoa(
        String.fromCharCode(
            ...new Uint8Array(buffer)
        )
    );
}

export function formatBytes(bytes: number): string{
    const sizes = [ 'Bytes', 'KB', 'MB', 'GB', 'TB' ];

    if (bytes === 0) {
        return '-'
    }

    const i = Math.floor(Math.log2(bytes) / 10)

    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
}
